/** @jsx jsx */
import { Box, Grid, Flex, Heading, Text, Container, jsx } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ThemeUIStyleObject } from "@theme-ui/css"

import type { Maybe } from "../../graphql-types"
import theme from "../gatsby-plugin-theme-ui/index"

import AguilaButton from "components/AguilaButton"
import Arrow from "components/common/Arrow"
import LineAnimation from "./common/LineAnimation"
import MdContent from "components/common/MdContent"
import RetailerLocator from "components/Locator/RetailerLocator"
import SectionHeading from "./SectionHeading"

interface PageHeroProps {
  //enables sx prop to be passed down from parent
  className?: string
  title: Maybe<string> | undefined
  image?: IGatsbyImageData
  subtitle?: Maybe<string> | undefined
  imageAlt?: Maybe<string> | undefined
  imagePosition?: string
  children?: React.ReactNode
  hasLocatorCTA?: boolean
  content?: Maybe<string> | undefined
  buttonOneText?: Maybe<string> | undefined
  buttonOneUrl?: Maybe<string> | undefined
  buttonOneVariant?: "primary" | "secondary" | "textOnly"
  buttonTwoText?: Maybe<string> | undefined
  buttonTwoUrl?: Maybe<string> | undefined
  buttonTwoVariant?: "primary" | "secondary" | "textOnly"
  backlinkUrl?: string
  hasLines?: boolean
  extraButtonInfo?: string
}

const PageHero = ({
  className,
  title,
  image,
  imageAlt,
  imagePosition = "center",
  children,
  hasLocatorCTA = false,
  content,
  buttonOneText,
  buttonOneUrl,
  buttonOneVariant = "primary",
  buttonTwoText,
  buttonTwoUrl,
  buttonTwoVariant = "textOnly",
  backlinkUrl,
  subtitle,
  hasLines,
  extraButtonInfo,
}: PageHeroProps) => {
  if (image !== undefined) {
    return (
      <Box
        as="section"
        sx={{ mb: [12, null, null, 24], position: "relative" }}
        className={className}
      >
        <Grid
          sx={{
            gridTemplateColumns:
              "[outer-left] minmax(16px, 1fr) [grid-left] minmax(0, 1188px) [grid-right] minmax(16px, 1fr) [outer-right]",
            gap: 0,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Flex
            sx={{
              gridColumn: "grid-left / outer-right",
              flexDirection: ["column", null, null, "row"],
              position: "relative",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 3,
                flex: ["1", null, null, "0 0 510px", null],
                py: 8,
                mb: [0, null, null, 8],
                pr: 4,
              }}
            >
              {backlinkUrl && (
                <Link
                  to={backlinkUrl}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    variant: "links.heroBacklink",
                    color: "primary",
                    mb: 6,
                  }}
                >
                  <Arrow arrowColor="primary" reverse sx={{ mr: 3 }} />
                  Back to Team Aguila
                </Link>
              )}

              <Heading as="h1" variant="hero" sx={{ color: "primary" }}>
                {title}
              </Heading>

              <Heading as="h2" variant="heading" sx={{ color: "text", mb: 6 }}>
                {subtitle}
              </Heading>

              {content && (
                <MdContent
                  source={content}
                  sx={{
                    mb: [8, null, null, 16],
                    ...(theme.styles as ThemeUIStyleObject),
                  }}
                />
              )}

              <Flex sx={{ flexWrap: "wrap", justifyContent: "start", alignItems: "center" }}>
                {buttonOneText && buttonOneUrl && (
                  <AguilaButton
                    url={buttonOneUrl}
                    text={buttonOneText}
                    sx={{ mr: 5, mb: 5, pr: 2.5, pl: 2.5, width: ["225px", null, "250px"], textAlign: "center" }}
                    variant={buttonOneVariant}
                  />
                
                )}
                {buttonTwoText && buttonTwoUrl && (
                  <Flex sx={{ flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                    <AguilaButton
                      url={buttonTwoUrl}
                      text={buttonTwoText}
                      sx={{ mr: 5, mb: 5, pr: 2.5, pl: 2.5, width: ["225px", null, "250px"], textAlign: "center" }}
                      variant={buttonTwoVariant}
                    />
                    
                    {/* <a
                      href={buttonTwoUrl}
                      download
                      sx={{ variant: `buttons.${buttonTwoVariant}` }}
                    >
                      <Text as="span" className="link-text">
                        {buttonTwoText}
                      </Text>
                      {(buttonTwoVariant === "secondary" ||
                        buttonTwoVariant === "textOnly") && (
                        <Arrow
                          arrowColor={
                            buttonTwoVariant === "secondary"
                              ? "textWhite"
                              : "primary"
                          }
                        />
                      )}
                    </a> */}
                  </Flex> 
                )}
              </Flex>
            </Box>
            <Grid
              sx={{
                position: "relative",
                zIndex: 0,
                mb: hasLines ? 10 : 0,
                ml: [0, null, null, 12],
                width: "100%",
                gridTemplateColumns: [
                  "21px minmax(330px, 705px) auto",
                  null,
                  null,
                  "55px minmax(330px, 705px) auto",
                ],
                gridTemplateRows: [
                  "17px 1fr 13px",
                  null,
                  null,
                  "70px 1fr 16px",
                ],
              }}
            >
              {hasLines && (
                <LineAnimation
                  sx={{ zIndex: 2, left: [0, null, "-50vw"] }}
                  variant="primary"
                />
              )}
              <Box
                sx={{
                  gridColumn: "1 / 3",
                  gridRow: "2 / 4",
                  maxHeight: ["250px", "380px"],
                }}
              >
                <GatsbyImage
                  image={image}
                  alt={imageAlt as string}
                  objectFit="cover"
                  objectPosition={imagePosition}
                  sx={{
                    width: "100%",
                    height: "100%",
                    // WebkitFilter: hasLines ? "grayscale(1)" : "none",
                    // filter: hasLines ? "grayscale(1)" : "none",
                  }}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: "tan",
                  gridColumn: "2 / 4",
                  gridRow: "1 / 3",
                  position: "relative",
                  zIndex: "-1",
                }}
              ></Box>
            </Grid>
          </Flex>
        </Grid>
        {hasLocatorCTA && (
          <RetailerLocator sx={{ mx: "auto", mt: hasLines ? -24 : -10 }} />
        )}
      </Box>
    )
  } else {
    return (
      <Box
        as="section"
        sx={{
          position: "relative",
          pt: 10,
          mb: 0,
          // mb: 28,
        }}
        className={className}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 50%, #D6D1C4 50%)",
            zIndex: 0,
            mb: [10, null, 36],
          }}
        >
          <LineAnimation variant="primary" />
        </Box>

        <Box sx={{ position: "relative", zIndex: 2 }}>
          <SectionHeading
            variant="hero"
            sx={{ color: "primary", mt: 6, mb: [4, null, 4] }}
          >
            {title}
          </SectionHeading>
          <Container>
            <Flex sx={{ flexWrap: "wrap", gap: 4 }}>
              {extraButtonInfo && (
                <Text
                  sx={{
                    display: "inline-block",
                    variant: "text.paragraphHeavy",
                    marginTop: 0,
                    pt: 2.5,
                  }}
                >
                  {extraButtonInfo}
                </Text>
              )}
              
              <Flex sx={{ flexWrap: "wrap", justifyContent: "start", alignItems: "center" }}>
              {buttonOneText && buttonOneUrl && (
                <AguilaButton
                  url={buttonOneUrl}
                  text={buttonOneText}
                  sx={{ mr: 5, mb: 5, pr: 2.5, pl: [0, 2.5, 2.5, 2.5, 2.5], alignSelf: "center" }}
                  variant={buttonOneVariant}
                />
              )}
              {buttonTwoText && buttonTwoUrl && (
                <Flex sx={{ flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                <AguilaButton
                  url={buttonTwoUrl}
                  text={buttonTwoText}
                  sx={{ mr: 5, mb: 5, pr: 2.5, pl: 2.5 }}
                  variant={buttonTwoVariant}
                />
                  
                  {/* <a
                    href={buttonTwoUrl}
                    download
                    sx={{ variant: `buttons.${buttonTwoVariant}` }}
                  >
                    <Text as="span" className="link-text">
                      {buttonTwoText}
                    </Text>
                    {(buttonTwoVariant === "secondary" ||
                      buttonTwoVariant === "textOnly") && (
                      <Arrow
                        arrowColor={
                          buttonTwoVariant === "secondary"
                            ? "textWhite"
                            : "primary"
                        }
                      />
                    )}
                  </a> */}
                </Flex>
              )}
              </Flex>
            </Flex>
            <Flex
              sx={{
                minHeight: ["120px", null, "240px"],
              }}
            >
              {content && (
                <Box
                  sx={{
                    flex: "1 auto",
                    ml: [10, null, 20],
                    mb: [8, null, 16],
                    // pb: [40, null, 72],
                  }}
                >
                  <MdContent
                    source={content}
                    sx={{
                      maxWidth: "600px",
                      mt: [0, null, 10],
                      mb: [24, null, 48],
                    }}
                  />
                </Box>
              )}
            </Flex>
          </Container>
        </Box>
        {hasLocatorCTA && (
          <Box sx={{ px: 4, mb: 10 }}>
            <RetailerLocator
              sx={{
                position: "absolute",
                px: 4,
                bottom: [0, null, 18],
                left: "50%",
                width: ["90%", null, "100%"],
                transformOrigin: "center center",
                transform: "translateX(-50%)",
              }}
            />
          </Box>
        )}
      </Box>
    )
  }
}

export default PageHero
